import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
} from "reactstrap";

// core components

const MapWrapper = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: -3.8037358999999995, lng: -38.5898303 }}
      defaultOptions={{
        scrollwheel: false,

        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [{ color: "#ffffff" }, { lightness: 17 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 18 }],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 16 }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#dedede" }, { lightness: 21 }],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              { visibility: "on" },
              { color: "#ffffff" },
              { lightness: 16 },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              { saturation: 36 },
              { color: "#333333" },
              { lightness: 40 },
            ],
          },
          { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [{ color: "#fefefe" }, { lightness: 20 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
          },
        ],
      }}
    >
      <Marker position={{ lat: -3.8037358999999995, lng: -38.5722303 }} />
    </GoogleMap>
  ))
);

function ContactUs() {
  return (
    <>
      <div className="cd-section" id="contato">
        <div className="contactus-2">
          <div className="big-map" id="contactUsMap">
            <MapWrapper
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyALD7ROsAABEse900xJVy6zxWxq4fiDHys"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
          <Col lg="6" md="10">
            <Card className="card-contact card-raised">
              <Form id="contact-form" method="post" role="form">
                <CardHeader className="text-center">
                  <CardTitle tag="h4">Entre em contato</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <div className="info info-horizontal">
                        <div className="icon icon-info">
                          <i className="now-ui-icons tech_mobile"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title">Telefone</h5>
                          <p>
                            <a href="tel: +55 85 3082-3439">+55 85 3082-3439</a>
                          </p>
                          <p >
                  <i className="fab fa-whatsapp"></i>&nbsp;
                  <a href="https://api.whatsapp.com/send?phone=5585987757000&text=Eu%20gostaria%20de%20marcar%20uma%20consulta.%20Qual%20seria%20o%20primeiro%20hor%C3%A1rio%20dispon%C3%ADvel%3F">+55 85 9877-57000</a>
                  </p>
                     </div>
                      </div>
                      <div className="info info-horizontal">
                        <div className="icon icon-info">
                          <i className="now-ui-icons tech_watch-time"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title">Horas de funcionamento</h5>
                          <p className="bold">
                            Segunda - Sexta, 10:00-19:00
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="info info-horizontal">
                        <div className="icon icon-info">
                          <i className="now-ui-icons location_pin"></i>
                        </div>
                        <div className="description">
                          <h5 className="info-title">Nosso Endereço</h5>
                          <p>
                          <a href="https://g.page/lapidarsaude?share">Av. Godofredo Maciel, 4000 - loja 2 - Maraponga<br></br>
                          Fortaleza - CE, 60711-502, Brazil</a><br></br>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
