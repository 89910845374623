import React from "react";
// reactstrap components
import { Card, CardText, CardBody, Container, Row, Col } from "reactstrap";

// core components

function Examples() {
  return (
    <>
      <div className="section section-examples bg-light" id="serviços">
        <Container>
          <Col className="ml-auto mr-auto text-center" md="8">
            <div className="section-description">
              <h2 className="title">Nossos Serviços</h2>
            </div>
          </Col>
          <Row>
            <Col md="4">
              <h5 className="title">Odontologia</h5>
              <Card>
                <CardBody>
                  <CardText>
                    <ul>
                      <li>
                        Ortodontia
                    </li>
                      <li>
                        Implantes
                    </li>
                      <li>
                        Lentes de Contato
                    </li>
                      <li>
                        Clareamento Dentário
                    </li>
                Facetas Dentais
                    <li>
                        Canal
                    </li>
                      <li>
                        Cirurgias
                    </li>
                      <li>
                        Restaurações estéticas
                    </li>
                    </ul>
                  </CardText>
                </CardBody>
              </Card>
              <h5 className="title">Massoterapia</h5>
              <Card>
                <CardBody>
                  <CardText>
                    <ul class="list0 nls-None" id="u602-11">
                      <li id="u602-4">Massagem Relaxante</li>
                      <li id="u602-6">Drenagem Linfática</li>
                      <li id="u602-8">Massagem Modeladora</li>
                      <li id="u602-10">Ventosas</li>
                    </ul>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <h5 className="title">Harmonização Facial</h5>
              <Card>
                <CardBody>
                  <CardText>
                    <ul class="list0 nls-None" id="u572-13">
                      <li id="u572-4">Preenchimento facial</li>
                      <li id="u572-6">preenchimento labial</li>
                      <li id="u572-8">Bichectomia</li>
                      <li id="u572-10">Lipo de papada</li>
                      <li id="u572-12">Rinomodelação</li>
                    </ul>
                  </CardText>
                </CardBody>
              </Card>
              <h5 className="title">Estética Corporal</h5>
              <Card>
                <CardBody>
                  <CardText>
                    <ul class="list0 nls-None" id="u584-13">
                      <li id="u584-4">Hidrolipoclasia Ultrassônica</li>
                      <li id="u584-6">Redução de medidas (criolipólise)</li>
                      <li id="u584-8">Tratamento para estrias</li>
                      <li id="u584-10">Tratamento para flacidez (radiofrequencia)</li>
                      <li id="u584-12">Clareamento de virilha e axila</li>
                    </ul>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">

              <h5 className="title">Estética Facial</h5>
              <Card>
                <CardBody>
                  <CardText>
                    <ul class="list0 nls-None" id="u578-13">
                      <li id="u578-4">Limpeza de pele</li>
                      <li id="u578-6">Microagulhamento</li>
                      <li id="u578-8">Peeling químico</li>
                      <li id="u578-10">Tratamento de melasmas</li>
                      <li id="u578-12">Rejuvenescimento</li>
                    </ul>
                  </CardText>
                </CardBody>
              </Card>
              <h5 className="title">Micropigmentação</h5>
              <Card>
                <CardBody>
                  <CardText>
                    <ul class="list0 nls-None" id="u584-13">
                      <li id="u584-4">Lábios</li>
                      <li id="u584-6">Sobrancelhas</li>
                    </ul>
                  </CardText>
                </CardBody>
              </Card>

              <h5 className="title">Ozonioterapia</h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Examples;
