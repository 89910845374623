import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";


function Headers() {
  // navbar collapses states and functions
  return (
    <>
      <div className="cd-section" id="headers">
        {/* <div className="header-1"> */}
        <div className="hero">
          <div
            className="page-header-image"
            style={{
              width: "100%",
              height: "100vh",
              position: "absolute",
              backgroundSize: "cover",
              backgroundImage: "url(" + require("assets/img/lapidar/9.webp") + ")",
            }}
          ></div>
          <Container>
            <Row>
              <Col className="ml-auto text-right text-white" md="7">
                <h1 className="title" style={{ visibility: "hidden" }}>Lapidar Saúde</h1>
                <h4 className="description text-white font-weight-bold">
                  Aqui você encontra profissionais qualificados nas áreas de <b>Odontologia, Harmonização Facial, Estética Facial e Corporal, Micropigmentação de lábios e sobrancelhas, Massoterapia e Ozonioterapia</b>.
                  </h4>
                <br></br>
                <div className="buttons">
                  <Button
                    className="img-raised btn-outline-light m-1 font-weight-bold"
                    color="info"
                    size="md"
                    href="https://api.whatsapp.com/send?phone=5585987757000&text=Eu%20gostaria%20de%20marcar%20uma%20consulta.%20Qual%20seria%20o%20primeiro%20hor%C3%A1rio%20dispon%C3%ADvel%3F"
                    target="_blank"
                  >
                    <i className="fab fa-whatsapp"></i>&nbsp;
                  AGENDE A SUA CONSULTA
                </Button>
                  <Button
                    className="img-raised btn-outline-light m-1 font-weight-bold"
                    color="light"
                    size="md"
                    href="https://www.instagram.com/lapidarsaude/"
                    target="_blank"

                  >
                    <i className="fab fa-instagram"></i>&nbsp;
                  INSTAGRAM
                  </Button>
                  <Button
                    className="img-raised btn-outline-light m-1 font-weight-bold"
                    color="info"
                    size="md"
                    href="tel:+558530823439"
                    target="_blank"
                  >
                    <i className="fa fa-phone fa-flip-horizontal"></i> &nbsp;
                    3082-3439
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default Headers;
