import React from "react";
// react plugin used to create DropdownMenu for selecting items

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

function AboutUs() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
          <div className="about-office" id="nossa-clinica">
            <Container>
              <Row className="text-center">
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">Conheça a nossa clínica</h2>
                  <h4 className="description">
                  Um espaço completo para o cuidado com a sua saúde e estética, em uma estrutura moderna e com preços acessíveis.
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/lapidar/facade.webp")}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/lapidar/reception.webp")}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/lapidar/clinica.webp")}
                  ></img>
                </Col>
                <Col md="6">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/lapidar/team3.webp")}
                  ></img>
                </Col>
                <Col md="6">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/lapidar/consultorio.webp")}
                  ></img>
                </Col>
              </Row>
            </Container>
          </div>
    </>
  );
}

export default AboutUs;
