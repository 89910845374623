import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function ScrollTransparentNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top"} color="white" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img
                alt="Clínica Lapidar Saúde"
                src={require("assets/img/lapidar/logo.png")}
              ></img>
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <NavItem>
                <NavLink href="#nossa-clinica">
                  <i className="now-ui-icons objects_diamond"></i>
                  <p>Clínica</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#equipe">
                  <i className="now-ui-icons business_badge"></i>
                  <p>Equipe</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#serviços">
                  <i
                    aria-hidden={true}
                    className="now-ui-icons files_paper"
                  ></i>
                  <p>Serviços</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#contato">
                  <i className="now-ui-icons location_pin"></i>
                  <p>
                    Contato
                </p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://www.instagram.com/lapidarsaude/">
                  <i className="fab fa-instagram"></i>
                  <p>
                    Insta
                </p>
                </NavLink>
              </NavItem>
              <NavItem>
                <Button
                  className="nav-link  font-weight-bold"
                  color="info"
                  href="https://api.whatsapp.com/send?phone=5585987757000&text=Eu%20gostaria%20de%20marcar%20uma%20consulta.%20Qual%20seria%20o%20primeiro%20hor%C3%A1rio%20dispon%C3%ADvel%3F"
                  target="_blank"
                ><i className="fab fa-whatsapp"></i>
                  <p>&nbsp;&nbsp;WHATSAPP</p>
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  className="nav-link btn-outline-info  font-weight-bold"
                  color="info"
                  href="tel:+558530823439"
                  target="_blank"
                >
                  <i className="fa fa-phone fa-flip-horizontal"></i>
                  <p>&nbsp;&nbsp;&nbsp;3082-3439</p>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ScrollTransparentNavbar;
