import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Features() {
  return (
    <>
      <div className="cd-section" id="diferenciais">
        <div className="features-3">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Nossos Diferenciais</h2>
                <h4 className="description">
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <div className="info info-hover">
                  <div className="icon icon-info icon-circle">
                    <i className="now-ui-icons objects_diamond"></i>
                  </div>
                  <h4 className="info-title">Mais Conforto</h4>
                </div>
              </Col>
              <Col md="3">
                <div className="info info-hover">
                  <div className="icon icon-warning icon-circle">
                    <i className="now-ui-icons emoticons_satisfied"></i>
                  </div>
                  <h4 className="info-title">Satisfação Guarantida</h4>
                </div>
              </Col>
              <Col md="3">
                <div className="info info-hover">
                  <div className="icon icon-success icon-circle">
                    <i className="now-ui-icons business_money-coins"></i>
                  </div>
                  <h4 className="info-title">Preço adequado</h4>
                </div>
              </Col>
              <Col md="3">
                <div className="info info-hover">
                  <div className="icon icon-primary icon-circle">
                    <i className="now-ui-icons location_pin"></i>
                  </div>
                  <h4 className="info-title">Localização ideal</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Features;
