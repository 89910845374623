/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";


// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container>
          <nav>
            <ul>
              <li>
                <a
                  href="https://www.lapidarsaude.com.br/"
                  target="_blank"
                >
                  CLÍNICA LAPIDAR SAÚDE
                </a>
              </li>
              <li>
                <Button
                  className="btn-link"
                  color="default"
                  href="https://api.whatsapp.com/send?phone=5585987757000&text=Eu%20gostaria%20de%20marcar%20uma%20consulta.%20Qual%20seria%20o%20primeiro%20hor%C3%A1rio%20dispon%C3%ADvel%3F"
                  outline
                >
                  <i className="fab fa-whatsapp"></i> WHATSAPP
                </Button>
              </li>
              <li>
                <Button
                  className="btn-link"
                  color="default"
                  href="https://www.instagram.com/lapidarsaude/"
                  outline
                >
                  <i className="fab fa-instagram"></i> INSTAGRAM
                </Button>
              </li>
              <li>
              <Button
                  className="btn-link"
                  color="default"
                  href="tel:+558530823439"
                  outline
                >
                  <i className="fa fa-phone fa-flip-horizontal"></i> 3082-3439
                </Button>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Clínica Lapidar Saúde.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
