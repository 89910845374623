import React from "react";

// core components
import ScrollTransparentNavbar from "sections/ScrollTransparentNavbar.js";
import ContactUs from "sections/ContactUs.js";
import Features from "sections/Features.js";
import Hero from "sections/Hero.js";
import Gallery from "sections/AboutUs.js";
import Services from "sections/Examples.js";
import Teams from "sections/Teams.js";
import FooterBlack from "sections/FooterBlack.js";

function Home() {
  React.useEffect(() => {
    document.documentElement.classList.remove("nav-open");
  });
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <div className="main">
          <Hero/>
          <Services/>
          <Gallery/>
          <Teams />
          <Features />
          <ContactUs />
          <FooterBlack />
        </div>
      </div>
    </>
  );
}

export default Home;
