import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Teams() {
  return (
    <>
      <div className="cd-section" id="equipe">
        <div
          className="team-3 bg-light"
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Profissionais</h2>
                <h4 className="description">
                  Esses são os responsáveis pelo seu sorriso e bem estar.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" lg="7" xl="6">
                <Card className="card-profile">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/lapidar/thig.jpg")}
                          ></img>
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">Dr. Thiago Carvalho</CardTitle>
                        <h6 className="category text-info">Ortodontista e Implantodontista</h6>
                        <p className="card-description">
                          CRO-CE 4106
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="ml-auto mr-auto" lg="7" xl="6">
                <Card className="card-profile">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/lapidar/ju.jpg")}
                          ></img>
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">Dra. Juliana Maia</CardTitle>
                        <h6 className="category text-info">
                          Odontologia Estética e Prótese
                        </h6>
                        <p className="card-description">
                          CRO- CE 4121
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="ml-auto mr-auto" lg="7" xl="6">
                <Card className="card-profile">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/lapidar/gui.jpg")}
                          ></img>
                      </div>
                    </Col>
                    
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">Dr. Guilherme Ottoboni</CardTitle>
                        <h6 className="category text-info">
                        Odontologia
                        </h6>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="ml-auto mr-auto" lg="7" xl="6"> 
                <Card className="card-profile">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/lapidar/manu.jpg")}
                          ></img>
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">Manu Carvalho</CardTitle>
                        <h6 className="category text-info">Micropigmentação</h6>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="ml-auto mr-auto" lg="7" xl="6"> 
                <Card className="card-profile">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/lapidar/adyla.jpg")}
                          ></img>
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">Adyla Cavalcante</CardTitle>
                        <h6 className="category text-info">Estética</h6>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Teams;
